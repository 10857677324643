import { ca } from '@cian/analytics';
import { EFrequency } from '../../types/savedSearch';

const notificationFrequencyMap: Record<EFrequency, string> = {
  [EFrequency.Asap]: 'Asap',
  [EFrequency.Hourly]: 'Hourly',
  [EFrequency.Daily]: 'Daily',
  [EFrequency.Weekly]: 'Weekly',
};

export function trackSubscribeSave(frequency: EFrequency) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'subscription',
    action: 'send',
    label: 'saved_search',
    page: {
      extra: {
        period: notificationFrequencyMap[frequency],
      },
    },
  });
}
