import * as React from 'react';
import { SaveSearchContainer, TOnSubmitSaveSearchParams, useSaveSearchEvents } from '../../../packages/SaveSearchModal';

import { trackSubscribeSave } from './tracking';

const onSubscribe = ({ frequency }: TOnSubmitSaveSearchParams) => trackSubscribeSave(frequency);

const SavedSearchContainer: React.FC = () => {
  useSaveSearchEvents(onSubscribe);

  return <SaveSearchContainer />;
};

// eslint-disable-next-line import/no-default-export
export default SavedSearchContainer;
